* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  border-radius: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

.width {
  max-width: 600px;
  margin: 0 auto;
  width: 92%;
}

.header {
  background-color: #111;
  color: white;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 10;

  .width {
    position: relative;
  }

  h1 {
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
  }

  a {
    color: white;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
  }
}

.home-search {
  padding: 40px 0;
  text-align: center;

  h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  input {
    border: 1px solid #ddd;
    padding: 0 24px;
    line-height: 54px;
    border-radius: 8px;
  }
}

@keyframes rotating {
  from {
    transform: translateY(-50%);
    transform: rotate(0deg);
  }
  to {
    transform: translateY(-50%);
    transform: rotate(360deg);
  }
}

.home-search-input {
  position: relative;

  svg {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    right: 24px;
    animation: 2s rotating infinite linear;
    opacity: 0;
    transition: 0.2s opacity;
  }

  &.searching svg {
    opacity: 1;
  }
}

.home-cryptos {
  padding-bottom: 60px;

  h2 {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.home-cryptos-list {
  border-top: 1px solid #ddd;
}

.home-crypto {
  a {
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }

  span {
    display: block;
  }
}

.home-crypto-image {
  width: 50px;
}

.home-crypto-name {
  flex: 1;
  font-size: 20px;
  color: #111;
  font-weight: 600;
}

.home-crypto-btc {
  color: #111;
  font-size: 16px;

  img {
    width: 16px;
    display: inline-block;
    margin: -3px 5px 0 0;
    vertical-align: middle;
  }
}

.home-crypto-usd {
  color: #666;
  font-size: 10px;
}

.show-header {
  padding: 40px 0;
  text-align: center;

  img {
    width: 80px;
    border-radius: 50%;
    margin: 0 auto;
  }

  h2 {
    font-size: 32px;
    margin-top: 10px;
  }
}

.show-graph {
  height: 200px;
  width: 100%;
}

.show-details {
  padding-top: 40px;
  padding-bottom: 60px;

  h2 {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.show-details-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  color: #666;

  h3 {
    font-size: 16px;
    color: #111;
  }
}
